import React from "react";
// import { Link } from "react-router-dom";
import classes from './Footer.module.scss';
import Phone from "../Shared/Phone";
import Email from "../Shared/Email";
import ContactForm from "../ContactForm/ContactForm";
import aws from "../../assets/images/partners/aws.png";
import bing from "../../assets/images/partners/bing.png";
import google from "../../assets/images/partners/google.png";
import shopifyExpert from "../../assets/images/partners/shopifyExpert.png";
import vend from "../../assets/images/partners/vend.png";
import vantiv from "../../assets/images/partners/vantiv.png";

const footer = (props) => {
  return (
    <footer id="contact" className={[classes.Footer, classes[props.FooterType]].join(' ')}>
      <div className={classes.ftTop}>
        <div className={classes.contactSec}>
          <div className={classes.medDef}>
            <div className={classes.infoSide}>
              <p className={classes.secTitle}>Contact Us</p><div className={classes.titleLine}/><div className={classes.clearfix}/>

              <div className={[classes.hlf, classes.hlf1].join(' ')}>
                <div className={classes.cblock}>
                  <p className={classes.cat}>Phone</p>
                  <Phone where="contact" />
                </div>

                <div className={classes.cblock}>
                  <p className={classes.cat}>Mailing Address</p>
                  <p>P.O. Box 599<br/>
                  Gulf Shores, Al 36547</p>
                </div>
              </div>

              <div className={[classes.hlf, classes.hlf2].join(' ')}>
                <div className={classes.cblock}>
                  <p className={classes.cat}>Email</p>
                  <Email where="contact" />
                  <br/>
                  <button type="button" data-uk-toggle="target: #extensions" className={[classes.modalBtn, classes.class2].join(' ')}>Team Emails</button>
                </div>

                <div className={classes.cblock}>
                  <p className={classes.cat}>Hours</p>
                  <p>Monday-Friday:<br className={classes.tw} /> 8am-5pm</p>
                </div>
              </div>
            </div>  

            <div className={classes.formSide}>
              <div className={classes.introText}><p className={classes.secTitle}>Get a Quote</p><div className={classes.titleLine}/><div className={classes.clearfix}/></div>
              <ContactForm />
            </div>
          </div>
        </div>
      </div>

      <div className={classes.partnerRowUp}>
        <div className={classes.prInner}>
          <div className={[classes.partner, classes.google, classes.pil].join(' ')}><img src={google} className={classes.prLogo} alt="Google Partners logo" /></div>
          <div className={[classes.partner, classes.aws, classes.pfull].join(' ')}><img src={aws} className={classes.prLogo} alt="High Seas is AWS certified" /></div>
          <div className={[classes.partner, classes.shop, classes.pfull].join(' ')}><img src={shopifyExpert} className={classes.prLogo} alt="Shopify Experts, High Seas Design House" /></div>
          <div className={[classes.partner, classes.bing, classes.pil].join(' ')}><img src={bing} className={classes.prLogo} alt="Official Bing Partners" /></div>
          <div className={[classes.partner, classes.vend, classes.pfull].join(' ')}><img src={vend} className={classes.prLogo} alt="Vend Experts, High Seas Design House" /></div>
          <div className={[classes.partner, classes.vantiv, classes.pil].join(' ')}><img src={vantiv} className={classes.prLogo} alt="Vantiv Partner logo" /></div>
        </div>

        <div className={classes.FooterBtmB}><p className={[classes.copy, classes.left].join(' ')}>© {(new Date().getFullYear())} High Seas Design House. All rights reserved.</p></div>
      </div>
    </footer>
  );
}

export default footer;





