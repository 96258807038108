import React, {Component} from 'react';
import classes from "./Shared.module.scss";
import styles from "../Header/Header.module.scss";

class Email extends Component {
  render() {
    let styleParent = classes;
    if (this.props.styleParent === 'styles') {
      styleParent = styles;
    }
    return (
      <a className={[styleParent.emailLink, styleParent[this.props.where]].join(' ')} href={"mailto:" + this.props.name + "@hsdesignhouse.com"}>
        {[this.props.name + "@hsdesignhouse.com"]}
      </a>
    )
  }
}
Email.defaultProps = {
  name: "info"
}
export default Email;