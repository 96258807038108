import React, {Component} from "react";
import classes from '../Footer/Footer.module.scss';

export default class ContactForm extends Component {
  constructor(props) {
    super(props);
    this.submitForm = this.submitForm.bind(this);
    this.state = {
      status: ""
    };
  }
  render() {
    const { status } = this.state;

    return (
      <form
        className={classes.contactForm}
        onSubmit={this.submitForm}
        action="https://formspree.io/f/xknyyynw"
        method="POST"
      >
        <p>
          If you are interested in getting a quote for a project, scheduling a
          meeting, or just looking for more information on the services we
          provide, fill out the form below or contact us today.
        </p>
        <hr />
        <div className={classes.clearfix} />

        <div className={[classes.formGroup, classes.fgname].join(" ")}>
          <label htmlFor="name">Full Name</label>
          <input
            className={classes.cfield}
            type="text"
            name="name"
            id="name"
            required="required"
          />
        </div>

        <div className={[classes.formGroup, classes.fgcompany].join(" ")}>
          <label htmlFor="company">Company</label>
          <input
            className={classes.cfield}
            type="text"
            name="company"
            id="company"
            required="required"
          />
        </div>
        <div className={classes.clearfix} />

        <div className={[classes.formGroup, classes.fgemail].join(" ")}>
          <label htmlFor="email">Email</label>
          <input
            className={classes.cfield}
            type="email"
            name="_replyto"
            id="email"
            required="required"
          />
        </div>

        <div className={[classes.formGroup, classes.fgphone].join(" ")}>
          <label htmlFor="phone">Phone</label>
          <input
            className={classes.cfield}
            type="text"
            name="phone"
            id="phone"
            required="required"
          />
        </div>
        <div className={classes.clearfix} />

        <div className={[classes.formGroup, classes.fgpref].join(" ")}>
          <label htmlFor="contact_pref">Preferred Contact Method</label>
          <select
            name="contact_pref"
            id="contact_pref"
            required="required"
            className={classes.cfield}
          >
            <option value="" disabled="">
              Select From List
            </option>
            <option value="email">Email</option>
            <option value="text">Text</option>
            <option value="call">Phone Call</option>
          </select>
        </div>
        <div className={classes.clearfix} />

        <div className={[classes.formGroup, classes.fgdets].join(" ")}>
          <label htmlFor="details">
            Give us some details about your project.
          </label>
          <textarea className={classes.cfield} name="details" id="details" />
        </div>

        <input type="text" name="_gotcha" style={{ display: "none" }} />
        <input
          type="hidden"
          name="_subject"
          id="email-subject"
          value="Contact/Quote Form Submission from Website"
        ></input>
        <div className={[classes.formGroup, classes.fgsub].join(" ")}>
          {status === "SUCCESS" ? (
            <div className={classes.successSub}>
              <p>Thank you for your interest in working with us! Someone from our team will reach out to you once we have reviewed your submission.</p>
            </div>
          ) : (
            <div className={classes.btnRow}>
              <button className={[classes.hsLink, classes.submit].join(" ")}>
                Submit
              </button>
            </div>
          )}
          {status === "ERROR" && (
            <p className={classes.errorSub}>
              Your submission contains an error. Please check your information,
              and try again.
            </p>
          )}
        </div>
      </form>
    );
  }

  submitForm(ev) {
    ev.preventDefault();
    const form = ev.target;
    const data = new FormData(form);
    const xhr = new XMLHttpRequest();
    xhr.open(form.method, form.action);
    xhr.setRequestHeader("Accept", "application/json");
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== XMLHttpRequest.DONE) return;
      if (xhr.status === 200) {
        form.reset();
        this.setState({ status: "SUCCESS" });
      } else {
        this.setState({ status: "ERROR" });
      }
    };
    xhr.send(data);
  }
}