import React from "react";
import { Helmet } from "react-helmet";
import classes from "./Websites.module.scss";
// import blaze from "../../assets/images/portfolio/blaze.png";
import mm158 from "../../assets/images/portfolio/mm158.png";
import marc from "../../assets/images/portfolio/marc.png";
import mold from "../../assets/images/portfolio/mold.png";
import jcmf from "../../assets/images/portfolio/jcmf.png";
// import arrow from "../../assets/images/arrow2.png";
// import arrowM from "../../assets/images/arrow2mob.png";

const websites = () => {
  return (
    <div className={[classes.ServePage, classes.Websites].join(' ')}>
      <Helmet>
        <title>Website Design & Development in Orange Beach, AL | High Seas Design House</title>
        <meta name="description" content="High Seas Design House has an experienced and skilled team of web designers. We build customized sites that produce quality results." />
      </Helmet>

      <div style={{display:'none'}}>
        {/* We are located in Orange Beach, Alabama, but extend our services far beyond. */}
        Usability 
        We create user friendly websites that are easy to navigate regardless of device or screen size. We strive to keep all interactions, transactions, and special 
        functionality as simple to execute as possible.


        Prioritized Layout
        {/* We work closely with our clients to achieve their goals, and bring their vision to life. We design each website with your target audience in mind. */}
        {/* Are you selling a product? Promoting an event? Spreading knowledge? What information will they be most interested in? */}
        We map out each website with the client's goals and target audience in mind.
        Whether you are selling a product, promoting an event, or spreading knowledge, we base our design around achieving your desired results, and display the most 
        relevant content most conveniently.






      
        High Seas has the experience and skills that you want in a design team, and a commitment to our clients that remains long after we launch their site. We design and 
        build websites customized to your needs, produce quality results, and offer support that you can't get from other agencies.

        When you work with us, you get full access to all of our creative talents. Every member of our team contributes something to each project, from general layout to 
        content creation. From the time we lift off, every phase of a project gets the attention it deserves.


        WE BELIEVE IN
        RESPONSIVE DESIGN
        The largest portion of web traffic these days comes from mobile devices and tablets. All of the sites we build are completely responsive, designed with a 
        prioritized layout, and a usability that remains functional on any device or screen size.

        USER FRIENDLY EXPERIENCE
        Our design team understands the value of usability, and we create websites that are user friendly and quick to load, with the important content conveniently 
        located, and any interactions or transactions being functional and simple to execute.

        WE DON'T BELIEVE IN
        Presenting our clients with anything we aren't proud to put our name on.

        AND WE HAVE VERY HIGH STANDARDS.
      </div>

      <div className={[classes.simpleHeader, classes.websites, classes.blob].join(' ')}>
        <div className={classes.med3Def}>
          <h1 className={classes.rotator}>We provide custom websites,<br className={classes.uno} /> <span className={classes.noRw}>built around your </span>
            <br className={classes.dos} />
            <span className={[classes.rwWords, classes.rwWords2].join(' ')}>
              <span className={classes.one}> brand.</span><br className={classes.set} />
              <span className={classes.two}> goals.</span><br className={classes.set} />
              <span className={classes.three}> needs.</span>
            </span>
          </h1>
        </div>
      </div>

      <div className={[classes.fullRow, classes.sec2Shape, classes.web2].join(' ')}>
        <div className={classes.csDiv}>
          <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
            <path d="M1200 0L0 0 598.97 114.72 1200 0z" class="shape-fill"></path>
          </svg>
        </div>
        <div className={classes.csDivFlip}>
          <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
            <path d="M1200 0L0 0 598.97 114.72 1200 0z" class="shape-fill"></path>
          </svg>
        </div>

        <p className={[classes.cat, classes.catL].join(' ')}>Usability</p>
        <p className={[classes.cat, classes.catR].join(' ')}>Prioritized Layout</p>
        <div className={classes.med2Def}>
          <div className={[classes.col, classes.left, classes.grid46].join(' ')}>
            <p>Our team creates <span className={classes.span1}>user friendly</span> websites that are <span className={classes.span2}>easy to navigate</span> regardless of device or screen size. 
              We aim to keep any and all interactions, transactions, and other special functionality within a site as <span className={classes.span3}>simple to execute</span> as possible.  
            </p>
          </div>

          <div className={[classes.col, classes.grid47, classes.right, classes.pull].join(' ')}>
            {/* <p className={classes.cat}>Prioritized Layout</p> */}
            {/* We work closely with our clients to achieve their goals, and bring their vision to life. We design each website with your target audience in mind. */}
            {/* Are you selling a product? Promoting an event? Spreading knowledge? What information will they be most interested in? */}
            <p>We map out each site with the client's goals and target audience in mind.
            Whether you are selling a product, promoting an event, or spreading knowledge, we base our design around achieving the desired results, and 
            conveniently displaying the most relevant content.</p>
          </div>
        </div>
      </div>    

      <div className={[classes.fullRow, classes.recentSlides].join(' ')} tabIndex="-1" data-uk-slider>
        <div className={classes.intro}>
          <div className={classes.med3Def}>
            <p className={classes.cat}>Why Choose High Seas?</p>
            <p>We have the experience and skills that you want in a design team, and a commitment to our clients that remains long after we launch their site. 
            We design and build websites customized to your needs, produce quality results, and offer support that you can't get from other agencies.</p>
          </div>
        </div>

        <div className={[classes.customWrap, classes.hsrWrap, "uk-position-relative"].join(' ')}>
          <div className={[classes.itemWrap, "uk-slider-container uk-light"].join(' ')}> 
            <div className={classes.iwInner}>
              <ul className={[classes.slItems, "uk-slider-items"].join(' ')}>
                <li className={[classes.hswItem, classes.mm, classes.noScroll].join(' ')}>
                  <div className={classes.frame}><img src={mm158} className={classes.hswImg} alt="Mile Marker 158 website, High Seas portfolio" /></div>
                </li>

                <li className={[classes.hswItem, classes.marc].join(' ')}>
                  <div className={classes.frame}><img src={marc} className={classes.hswImg} alt="Client website - Medical Air Rescue Company" /></div>
                </li>

                <li className={[classes.hswItem, classes.shortScroll, classes.mold].join(' ')}>
                  <div className={classes.frame}><img src={mold} className={classes.hswImg} alt="Website portfolio - Gulf Coast Mold Detection" /></div>
                </li>

                <li className={[classes.hswItem, classes.jcmf].join(' ')}>
                  <div className={classes.frame}><img src={jcmf} className={classes.hswImg} alt="Jennifer Claire Moore Foundation Website" /></div>
                </li>              
              </ul>
            </div>


            <div className={classes.instr}>
              <button className={[classes.slideNav, classes.arrowLeft, "uk-position-center-left Xuk-position-small"].join(' ')} data-uk-icon="arrow-left" uk-slider-item="previous"></button>
              <p>View More</p>
              <button className={[classes.slideNav, classes.arrowRight, "uk-position-center-right Xuk-position-small"].join(' ')} data-uk-icon="arrow-right" uk-slider-item="next"></button>
            </div>

            {/* <button className={[classes.slideNav, classes.outNav, classes.arrowLeft, "uk-position-center-left"].join(' ')} data-uk-icon="arrow-left" uk-slider-item="previous"></button>
            <button className={[classes.slideNav, classes.outNav, classes.arrowRight, "uk-position-center-right"].join(' ')} data-uk-icon="arrow-right" uk-slider-item="next"></button> */}
          </div>
        </div>
      </div>




    </div>
  );
}
export default websites;