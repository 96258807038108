import React from "react";
import { Helmet } from "react-helmet";
// import { Link } from "react-router-dom";
// import png from "../../assets/images/fleetSolo2.png";
const classes = require('./Fallback.module.scss');

const fallback = () => {
  return (
    <div className={classes.FallbackPage}>
      <Helmet>
        <title></title>
        <meta name="description" content="" />
      </Helmet>

      <div className={classes.fbWrap}>
        <div className={classes.medDef}>
          <p>Looks like you took a wrong turn.<br/> Try one of the links below to get back on track.</p>
        </div>
      </div>

    </div>
  );
}
export default fallback;