import React, {Component} from 'react';
import { Link } from "react-router-dom";
import classes from "./Header.module.scss";
// import shopify from "../../assets/images/shopifyText.png";
import shopifyW from "../../assets/images/shopifyTextW.png";
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Collapse from '@material-ui/core/Collapse';
const dropIcon = (<span className={classes.parentIcon} data-uk-icon="icon: chevron-down;"></span>);

export function SimpleCollapse() {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const handleChange = () => {
    setOpen((prev) => !prev);
  };
  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };
  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);

  // const companyList=();
  // let listItems = "";
  // props.parent === "Company" ? listItems = companyList : listItems = baseList;

  return (
    <li className={[classes.navItem, classes.withDrop].join(' ')}>
      <Link to="#" ref={anchorRef} onClick={handleChange} className={classes.parentRule}>Services {dropIcon}</Link>

      <Collapse in={open} className={classes.dropItem} {...(open ? {} : { timeout:0 })}>
        <ClickAwayListener onClickAway={handleClose}>
          <ul className={classes.menuDrop} onKeyDown={handleListKeyDown}>
            <li className={classes.dropLi} onClick={handleClose}><Link to="/websites">Web Design</Link></li>
            <li className={classes.dropLi} onClick={handleClose}><Link to="/ecommerce">eCommerce</Link></li>
            <li className={classes.dropLi} onClick={handleClose}><Link to="/digital-advertising">Digital Advertising</Link></li>
            <li className={[classes.navItem, classes.imgNav, classes.mobOnly].join(' ')}><Link to="/shopify"><img src={shopifyW} alt="Shopify" className={classes.navImg} /></Link></li>
          </ul>
        </ClickAwayListener>
      </Collapse>
    </li>
  );
}

class NavItems extends Component {
  render() {
    return(
      <ul className={[classes[this.props.navClass], classes[this.props.addClass]].join(' ')}>
        <li className={[classes.navItem, classes.homelink].join(' ')}><Link to="/">Home</Link></li>
        <SimpleCollapse parent="Services" />

        <li className={[classes.navItem, classes.icomp].join(' ')}><Link to="/company">Company</Link></li>
        <li className={[classes.navItem, classes.icont].join(' ')}><a href="#contact" data-uk-scroll>Contact</a></li>
        <li className={[classes.navItem, classes.imgNav, classes.fwOnly].join(' ')}><Link to="/shopify"><img src={shopifyW} alt="Shopify" className={classes.navImg} /></Link></li>
        {/* <li className={[classes.navItem, classes.imgNav, classes.mobOnly].join(' ')}><Link to="/shopify"><img src={shopify} alt="Shopify" className={classes.navImg} /></Link></li> */}
      </ul>
    );
  }
}
export default NavItems;