import React from "react";
import classes from "./Shopify.module.scss"
import shopifyLogo from "../../assets/images/shopifyPage.png";
import img1 from "../../assets/images/hsgEcom.png";
import barrow1 from "../../assets/images/shopify/barrow.png";
import barrow2 from "../../assets/images/shopify/barrowColl.png";
import barrow3 from "../../assets/images/shopify/barrowColl2.png";
import spearing1 from "../../assets/images/shopify/spearing0.png";
import spearing2 from "../../assets/images/shopify/spearing1.png";
import spearing3 from "../../assets/images/shopify/spearing3.png";
import fsc0b from "../../assets/images/shopify/fsc0b.png";
import fsc1 from "../../assets/images/shopify/fsc1.png";
import fsc3 from "../../assets/images/shopify/fsc3.png";
import shades from "../../assets/images/shopify/shades1.png";
import zippix from "../../assets/images/shopify/zippix1.png";
import frank from "../../assets/images/shopify/frank1.png";

// {/* eCommerce has become one of the most effective tools for cultivating a business, and our resume has eCommerce written all over it. */}      
// {/* Our team believes in exemplary work, and outstanding customer service, for every project, big or small. */}
// {/* 
//   HSDH + Shopify Experts Logo = Shopify Experts Logo
  
//   Our team specializes in Shopify sites.
//   We love helping our clients take their business to the next level with an eCommerce store, designed specifically for them.

//   Start Your Shopify Free Trial https://www.shopify.com/?ref=HighSeasDesignHouse
// */}


const content1 = (props) => {
  return (
    <div className={[classes.EcomContent, classes[props.where]].join(' ')}>
      <div className={[classes.simpleHeader, classes.ecom, classes[props.where]].join(' ')}>
        <div className={[classes.med2Def, classes[props.where]].join(' ')}>
          {props.header}
          <p>High Seas is a serious asset to any business ready to transition into online sales. We specialize in Shopify sites, 
            {props.where === "shopify" ?
              ' and have developed our strong eCommerce background by taking on projects of all sizes, producing websites for businesses of all types.' 
            :
              ' but have developed our strong eCommerce background by taking on projects of all sizes, producing websites for businesses of all types, and working with many of the various sales platforms.'
            }
          </p>
          <img src={shopifyLogo} alt="High Seas Design - Shopify Experts logo" className={classes.logo} />
        </div>
      </div>

      <div className={[classes.fullRow, classes.sec2Shape, classes.ecom2].join(' ')}>
        <img src={img1} alt="eCommerce graphic"  className={[classes.imgAbs, classes.imgTwist].join(' ')} />
    
        <div className={classes.csDiv}>
          <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
            <path d="M1200 0L0 0 598.97 114.72 1200 0z" class="shape-fill"></path>
          </svg>
        </div>
        <div className={classes.csDivFlip}>
          <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
            <path d="M1200 0L0 0 598.97 114.72 1200 0z" class="shape-fill"></path>
          </svg>
        </div>

        <p className={classes.catR}>Why Sell Online?</p>
        <div className={classes.medDef}>
          <div className={[classes.col, classes.right, classes.grid40].join(' ')}>
            <ul className={classes.ilul}>
              <li>Customer convenience</li>
              <li>Build your brand</li>
              <li>Grow your clientele</li>
              <li>Increase your in-store traffic</li>
            </ul>
          </div>
        </div>
      </div>

      <div className={[classes.fullRow, classes.ecom3].join(' ')}>
        <div className={classes.med2Def}>
          <div className={classes.titleBox}><p className={classes.title}>How Can We Help?</p></div>

          <div className={[classes.col, classes.left, classes.grid40, classes.i1].join(' ')}>
            <p className={classes.cat}>NEW STORES</p>
            <p>Our team will handle the initial set up for your new Shopify store, and build a beautiful, custom storefront, tailored to represent your brand.</p>
          </div>

          <div className={[classes.col, classes.right, classes.grid41, classes.i2].join(' ')}>
            <p className={classes.cat}>EXISTING STORES</p>
            <p>Already have a Shopify store that just needs a little TLC? Our design team will work their magic, and leave your site with a fresh look that feels brand new.</p>
          </div>
          
          <div className={classes.clearfix}/>

          <div className={[classes.col, classes.left, classes.grid44, classes.i3].join(' ')}>
            <p className={classes.cat}>MIGRATION</p>
            <p>Do you have an eCommerce store on another platform, but want to migrate to Shopify? No problem! Our setup experts will have you up and running in no time.</p>
          </div>

          <div className={[classes.col, classes.right, classes.grid42, classes.i4].join(' ')}>
            <p className={classes.cat}>SUPPORT</p>
            <p>Before you take over, we train you to manage your store - - from product entry and updates, to fulfillment options. And we offer support long after the site is launched.</p>
          </div>

          {/* <div className={classes.clearfix}/><p>1. Help choose the platform that is right for your business, and set up your online store.</p> */}
        </div>
      </div>

      <div className={[classes.fullRow, classes.ecom4].join(' ')}>{/* data-uk-grid */}
        <div className={[classes.csDiv, classes.ecomPull].join(' ')}>
          <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
            <path d="M1200 0L0 0 598.97 114.72 1200 0z" class="shape-fill"></path>
          </svg>
        </div>

        <div className={classes.instr}><p>Swipe through the projects below to view more.</p></div>
        <div className={classes.clearfix} />
        <div className={[classes.ecomSlides].join(' ')} tabIndex="-1" data-uk-slider>
          <div className="uk-position-relative">
            <div className="uk-slider-container uk-light">
              <ul className={[classes.slItems, "uk-slider-items"].join(' ')}>
                <li className={[classes.slide, classes.fscSlide].join(' ')}>
                  <div className={classes.medDef}>
                    <div className={[classes.col, classes.left, classes.imageMain].join(' ')}>
                      <img src={fsc0b} alt="Shopify client, collection screen shot" className={[classes.efolImg, classes.ei2x].join(' ')} />
                    </div>
                    <div className={[classes.col, classes.right, classes.imageGroup].join(' ')}>
                      <img src={fsc1} alt="Shopify client, collection screen shot" className={[classes.efolImgB, classes.ei1].join(' ')} />
                      <img src={fsc3} alt="Shopify client, collection screen shot" className={[classes.efolImgB, classes.ei2].join(' ')} />
                    </div>
                  </div>
                </li>

                <li className={[classes.slide, classes.spearSlide].join(' ')}>
                  <div className={classes.medDef}>
                    <div className={[classes.col, classes.left, classes.imageMain].join(' ')}>
                      <img src={spearing1} alt="Spearing Magazine website client" className={[classes.efolImg, classes.ei].join(' ')} />
                    </div>
                    <div className={[classes.col, classes.right, classes.imageGroup].join(' ')}>
                      <img src={spearing3} alt="Spearing Magazine Shopify page" className={[classes.efolImgB, classes.ei1].join(' ')} />
                      <img src={spearing2} alt="Shopify portfolio, Spearing Magazine" className={[classes.efolImgB, classes.ei2].join(' ')} />
                    </div>
                  </div>
                </li>

                <li className={[classes.slide, classes.comboSlide].join(' ')}>
                  <div className={classes.medDef}>
                    <div className={[classes.cfolImg, classes.shades].join(' ')}><img src={shades} alt="Shades Sunglasses eCommerce site" /></div>
                    <div className={[classes.cfolImg, classes.zip].join(' ')}><img src={zippix} alt="Zippix eCommerce site" /></div>
                    <div className={[classes.cfolImg, classes.frank].join(' ')}><img src={frank} alt="Shopify client - Frank & Co" /></div>
                  </div>
                </li>

                <li className={[classes.slide, classes.barrowSlide].join(' ')}>
                  <div className={classes.medDef}>
                    <div className={[classes.col, classes.left, classes.imageMain].join(' ')}><img src={barrow1} alt="eCommerce client - Barrow Fine Furniture" className={classes.efolImg} /></div>
                    <div className={[classes.col, classes.right, classes.imageGroup].join(' ')}>
                      <img src={barrow3} alt="eCommerce site collection page" className={[classes.efolImgB, classes.ei1].join(' ')} />
                      <img src={barrow2} alt="Shopify client, collection screen shot" className={[classes.efolImgB, classes.ei2].join(' ')} />
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>

          <button className={[classes.slideNav, classes.arrowLeft, "uk-position-center-left"].join(' ')} data-uk-slidenav-previous uk-slider-item="previous"></button>
          <button className={[classes.slideNav, classes.arrowRight, "uk-position-center-right"].join(' ')} data-uk-slidenav-next uk-slider-item="next"></button>
        </div>
      </div>
    </div>
  );
}
export default content1;