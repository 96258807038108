import React, {Component} from 'react';
import { Link } from "react-router-dom";
import classes from "./Header.module.scss";
import NavItems from "./NavItems";
import Drawer from "./Drawer";
import Phone from "../Shared/Phone";
import Email from "../Shared/Email";
import logo from "../../assets/images/logoText.png";
// import logoMob from "../../assets/images/logoTextColor.png";
// const dropIcon = (<span className={classes.parentIcon} data-uk-icon="icon: chevron-down;"></span>);

class Header extends Component {
  render() {
    return (
      <header className={[classes.Header, classes.hdWave, classes[this.props.HeaderType]].join(' ')}>
        {/* <Link to="/" className={[classes.logoLink, classes.logoLinkMob].join(' ')}>
          <img src={logoMob} alt="Logo" className={[classes.logoImg, classes.logoImgMob].join(' ')} />
        </Link> */}

        <div className={classes.waveArea}>
          <div className={classes.waveWrap}>
            <svg viewBox="0 0 500 150" preserveAspectRatio="none" className={classes.wave}>
              {/*OG: */} 
              {/* <path d="M0.00,49.98 C-253.94,367.59 366.25,-112.98 501.12,50.81 L500.00,150.00 L0.00,150.00 Z" style={{stroke:'none', fill:'#fff' }}></path> */}
              <path d="M0.00,19.98 C-233.94,357.59 366.25,-135.98 501.12,50.81 L500.00,150.00 L0.00,150.00 Z" style={{stroke:'none', fill:'#fff' }}></path>
                    {/* 50.81 > 40  */}
            </svg>
          </div>

          <div className={[classes.inside, classes.hdWrap].join(' ')}>
            <nav className={classes.navMm}>
              <div className={classes.nmTop}>
                <div className={[classes.hdCol, classes.colLogo].join(' ')}>
                  <Link to="/" className={[classes.logoLink, classes.logoLinkFw].join(' ')}>
                    <img src={logo} alt="Logo" className={[classes.logoImg, classes.logoImgFw].join(' ')} />
                  </Link>

                  <div className={[classes.hdCol, classes.infoB].join(' ')}>
                    <Email where="navbar" styleParent="styles" />
                    <div className={classes.clearfix} />
                    <Phone where="navbar" styleParent="styles" />
                  </div>
                </div>


                <div className={[classes.hdCol, classes.ilNav].join(' ')}>
                  <NavItems navClass="waveNav" addClass="navArea" />
                </div>

                <div className={[classes.mobNav, classes.mnSolo].join(' ')}><Drawer /></div>

                {/* <div className={classes.clearfix} />
                <div className={[classes.hdCol, classes.info].join(' ')}>
                  <Email where="navbar" styleParent="styles" />
                  <Phone where="navbar" styleParent="styles" />
                </div> */}
              </div>
            </nav>          
          </div>
        </div>
      </header>
    );
  }
}

export default Header;


