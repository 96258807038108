import React from "react";
const classes = require('./Social.module.scss');

const social = (props) => {
  return (
    <ul className={[classes.Social, classes[props.where]].join(' ')}>
      <li><a href="https://www.facebook.com/HighSeasDesignHouse/?fref=ts" target="_blank" rel="noopener noreferrer">
        <span className={classes.SocialIcon} aria-label="High Seas Design House Facebook Link" uk-icon="icon: facebook"></span>
      </a></li>

      <li><a href="https://www.instagram.com/highseasdesign/" target="_blank" rel="noopener noreferrer">
        <span aria-label="High Seas Design House Instagram Link" uk-icon="icon: instagram"></span>
      </a></li>

      {/* <li><a href="https://twitter.com/" target="_blank" rel="noopener noreferrer">
        <span aria-label="High Seas Design House Twitter Link" uk-icon="icon: twitter"></span>
      </a></li> */}
    </ul>
  );
};

export default social;
