import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import classes from "./Home.module.scss";
// import arrow from "../../assets/images/arrow2mob.png";
// import scene from "../../assets/images/homeBgEd.png";
import scene1 from "../../assets/images/homeBg1.png";
import scene2 from "../../assets/images/homeBg2.png";
import agam from "../../assets/images/portfolio/agam.png";
import bif from "../../assets/images/portfolio/bif.png";
// import mm158 from "../../assets/images/portfolio/MM158.png";
import blaze from "../../assets/images/portfolio/blaze.png";
import redFlag from "../../assets/images/portfolio/redFlag.png";
import hsfImage from "../../assets/images/homeAreas4.png";

const review1 = (
  <div className={[classes.review, classes.revLong, classes.revOne].join(' ')}>
    <p className={classes.bold}>“At Bright Ideas Family, we needed a large, multifunctional website that could not only encompass all of our current childcare centers 
    webpages across the Southeast, but could also grow with us as we expand across the country. High Seas Design House was the perfect company for the job. They helped us 
    plan and map out the entire site, build up our new brand, implement hundreds of constant changes throughout, and created a website that everyone loves. We highly 
    recommend High Seas for all your website needs.”</p>
    <p className={classes.name}>- Ryan Robinson, Chief Marketing Officer</p>
  </div>
);
const review2 = (
  <div className={[classes.review, classes.revChoc, classes.revMid].join(' ')}>
    <p className={classes.Xbold}>“After hiring High Seas Design to create our logo and website, we have seen an increase in traffic on the site and in store. The website is incredibly easy to maintain, and I could not be more thrilled with the decision. The customer service was, and still is, top notch at every corner. Thanks again!”</p>
    <p className={classes.name}>- Chocolate Corner and Ice Cream</p>
  </div>
);
const review3 = (
  <div className={[classes.review, classes.revLong, classes.revCronin, classes.revLast].join(' ')}>
    <p className={classes.bold}>“Working with High Seas is an awesome experience. They really go out of their way to make sure I get everything I need. My 
    website and product photos turned out great, and they were very thorough when they showed me how everything worked. I've used them for a number of projects over 
    the years, and I'm always extremely satisfied.”</p>
    <p className={classes.name}>- Craig Cronin, Cronin Surfboards LLC</p>
  </div>
);

const home = (props) => {
  return (
    <div className={classes.HomePage}>
      <Helmet>
        <title>Websites, eCommerce, & Digital Advertising in Orange Beach, Al</title>
        <meta name="description" content="High Seas Design House is a full-service, digital agency in Orange Beach, Al. We specialize in website and eCommerce design, digital advertising, and videography." />
      </Helmet>

      <div style={{display:'none'}}>
        {/* 
          The full-service, digital agency you've been searching for
          Combining innovative ideas, with technical knowledge.
          Our team likes making complicated things. Not making things complicated.


          ECOMMERCE
          When it comes to Ecommerce, our team feels right at home. We have developed a significant Ecommerce background, with experience in a number of the various platforms. 
          High Seas can be a huge asset to Ecommerce projects of any size.



          // WORK page
          As a full service agency, High Seas goes further than most others to achieve the goals of our clients. When you bring us on board, we adopt the same 
          objectives you have for your business, and use them as a target for the work we do. We are proud of the relationships we have with our clients, and we work very 
          hard to maintain them.



          // PHOTOS //
          Because professionally taken photos are a very powerful sales tool, when High Seas is hired to build a website, we include a visit from our photographer.
          Showcasing high-quality images associated with your work, products you sell, or even a nice office space can be incredibly influential in convincing someone 
          to bring their business to you. Similarly, displaying low-quality, obscure photos can have the opposite effect.
          This is especially true for ecommerce sites, when product photos can make or break a sale.
        */}
      </div>

      <div className={[classes.homeSec1, classes.v3].join(' ')}>
        <div className={classes.wrap18}>
          <div className={classes.hs1Top}>
            <div className={classes.text}>
              <div className={classes.innerText}>
                <h1>Dedicated Digital Partners</h1>
                <h2>For Your <span>Brand.</span> For Your <span>Business.</span> <br/>For Your <span>Future.</span></h2>

                <div className={classes.btnRow}>
                  <ul className={[classes.tabNav, classes.centerNav].join(' ')} data-uk-tab="connect: #tabs; animation: uk-animation-fade">
                    <li className={[classes.iconBtn, classes.dets, [props.activeTab === "dets" ? "uk-active" : null]].join(' ')}><Link to={{pathname:"#serviceSummary", activeTab: "dets"}} className={classes.hsLink} data-uk-scroll><span uk-icon="icon: cog"></span></Link></li>
                    <li className={[classes.web, [props.activeTab === "web" ? "uk-active" : null]].join(' ')}><Link to={{pathname:"#serviceSummary", activeTab: "web"}} className={classes.hsLink} data-uk-scroll>Web Design &amp; eCommerce</Link></li>
                    <li className={[classes.sem, [props.activeTab === "sem" ? "uk-active" : null]].join(' ')}><Link to={{pathname:"#serviceSummary", activeTab: "sem"}} className={classes.hsLink} data-uk-scroll>Digital Advertising</Link></li>
                  </ul>
                </div>
              </div>
            </div>
            <div id="serviceSummary" className={classes.fullRow}></div>
        
            <div className={[classes.imageGroup, classes.img1].join(' ')}><img src={scene1} className={classes.hs1Img} alt="website design graphic" /></div>
            <div className={[classes.imageGroup, classes.img2].join(' ')}><img src={scene2} className={classes.hs1Img} alt="eCommerce website graphic" /></div>
          </div>
        </div>

        <div className={[classes.homeSec2].join(' ')}>
          <div className={classes.hs2Wrap}>
          <div className={classes.hs2Inner}>
            <div className={[classes.tabContent, classes.hs2Tabs].join(' ')}>
              <ul id="tabs" className={[classes.tabContent, "uk-switcher"].join(' ')}>
                <li className={[classes.tcBlock, classes.tc1].join(' ')}>
                  <p className={classes.catB}>High Seas Design House</p>
                  <p>High Seas Design House is a full-service agency located in Orange Beach, Alabama. We provide customized, creative, and effective digital solutions for individuals, 
                    companies, and organizations looking to modernize their business, and surpass their competitors. </p>

                    <p>Our services range from website and eCommerce design, to digital advertising, as well as videography work. <a href="#contact" className={classes.textLink} data-uk-scroll>Contact us today</a> for 
                    more information on what we can do for you, or to get started on your next project!
                    </p>
                </li>
                <li className={[classes.tcBlock, classes.tc2, classes.subTc].join(' ')}>
                  <p className={classes.catB}>Web Design &amp; Development</p>
                  <p>
                    Our team designs engaging, purpose-driven websites that promote your business, extend your brand, and achieve your goals. We aim to create a 
                    sophisticated, yet straightforward interface that helps you connect with your audience, and provide them with a positive user experience. 
                    {/* anticipates and answers their questions -- sophisticated yet simple  effortless  uncomplicated  accessible */}
                    {/* We help you identify and connect with your audience. */}
                  </p>
                  <div className={classes.btnRow}>
                    <a href="/websites" className={classes.hsLinkRev}>Websites</a>
                    <a href="/ecommerce" className={classes.hsLinkRev}>eCommerce Sites</a>
                  </div>
                </li>

                <li className={[classes.tcBlock, classes.tc3, classes.subTc].join(' ')}>
                  <p className={classes.catB}>Digital Advertising</p>
                  <p>We offer tremendously effective digital advertising services. Experience a compelling spike in your business with the help of our powerful digital solutions, 
                    Google Ads campaigns, SEO, and remarketing tools.</p>

                  <div className={classes.btnRow}>
                    <a href="/digital-advertising" className={classes.hsLinkRev}>Learn More</a>
                  </div>
                </li>
              </ul>
            </div>
            </div>
          </div>
        </div>
      </div>

      <div className={[classes.fullRow, classes.recentSlides].join(' ')} tabIndex="-1" data-uk-slider>          
        <div className={[classes.customWrap, classes.hsrWrap, "uk-position-relative"].join(' ')}>
          <div className={classes.titleBtn}>
            <div className={classes.tbWrap}>
              <p className={classes.combo}><span className={classes.press}>Recent</span><br className={classes.br1} /> <span className={classes.spread}>Proj<br className={classes.br2} />ects</span></p>
            
              <div className={[classes.instr, classes.mobile].join(' ')}>
                <button className={[classes.slideNav, classes.arrowLeft, "uk-position-center-left Xuk-position-small"].join(' ')} data-uk-icon="arrow-left" uk-slider-item="previous"></button>
                <p>View More</p>
                <button className={[classes.slideNav, classes.arrowRight, "uk-position-center-right Xuk-position-small"].join(' ')} data-uk-icon="arrow-right" uk-slider-item="next"></button>
              </div>
            </div>
          </div>

          <div className={[classes.itemWrap, "uk-slider-container uk-light"].join(' ')}> 
            <div className={classes.iwInner}>
              <ul className={[classes.slItems, "uk-slider-items"].join(' ')}>
                <li className={[classes.hswItem, classes.bif].join(' ')}><div className={classes.frame}><img src={bif} alt="High Seas design portfolio - Bright Ideas Family" /></div></li>
                <li className={[classes.hswItem, classes.flag].join(' ')}><div className={classes.frame}><img src={redFlag} alt="Web design for Red Flag Surf & Dive" /></div></li>
                <li className={[classes.hswItem, classes.blaze].join(' ')}><div className={classes.frame}><img src={blaze} alt="Grooms by Blaze website design" /></div></li>
                <li className={[classes.hswItem, classes.shortScroll, classes.agam].join(' ')}><div className={classes.frame}><img src={agam} alt="All Green Alabama Medical website screen shot" /></div></li>
              </ul>
            </div>

            <div className={[classes.instr, classes.fw].join(' ')}>
              <button className={[classes.slideNav, classes.arrowLeft, "uk-position-center-left Xuk-position-small"].join(' ')} data-uk-icon="arrow-left" uk-slider-item="previous"></button>
              <p>View More</p>
              <button className={[classes.slideNav, classes.arrowRight, "uk-position-center-right Xuk-position-small"].join(' ')} data-uk-icon="arrow-right" uk-slider-item="next"></button>
            </div>
          </div>
        </div>
      </div>

      <div className={classes.homeSecReviews}>
        <div className={[classes.clearfix, classes.rules].join(' ')} />
        <div className={classes.medDef}>
          <div className={classes.titleBoxAbs}><p>Ask Our Clients</p></div>
          {review1}
          {review2}
          <div className={classes.clearfix} />

          {review3}
          <div className={[classes.review, classes.imageBox].join(' ')}><img src={hsfImage} className={classes.hs1Img} alt="ph alt" /></div>
        </div>
        <div className={[classes.clearfix, classes.rules].join(' ')} />
      </div>

      <div className={classes.reviewsMob} tabIndex="-1" data-uk-slider="autoplay:true"> 
        <div className={[classes.hsrSec, "uk-position-relative"].join(' ')}>
          <div className={classes.titleBoxAbs}>
            <div className={classes.insideXl}>
              <p className={classes.one}>Ask Our Clients</p>
              <div className={classes.rNav}>
                <p>View More</p>
                <button className={[classes.slideNav, classes.arrowLeft, "uk-position-center-left Xuk-position-small"].join(' ')} data-uk-icon="arrow-left" uk-slider-item="previous"></button>
                <button className={[classes.slideNav, classes.arrowRight, "uk-position-center-right Xuk-position-small"].join(' ')} data-uk-icon="arrow-right" uk-slider-item="next"></button>
              </div>
            </div>
          </div>

          <div className={classes.insideN}>
            <div className={[classes.hsrWrap, "uk-slider-container uk-light"].join(' ')}> 
              <ul className={[classes.hsrList, "uk-slider-items"].join(' ')}>
                <li className={[classes.hsrItem, classes.i1].join(' ')}>{review1}</li>
                <li className={[classes.hsrItem, classes.i2].join(' ')}>{review2}</li>
                <li className={[classes.hsrItem, classes.i3].join(' ')}>{review3}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>


    </div>
  );
}
export default home;