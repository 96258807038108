import React from "react";
import { Helmet } from "react-helmet";
import classes from './Company.module.scss';
import { Link } from "react-router-dom";
// import Phone from "../../components/Shared/Phone";
// import Email from "../../components/Shared/Email";

const company = () => {
  return (
    <div className={classes.CompanyPage}>
      <Helmet>
        <title>Custom Websites & Digital Marketing on the Gulf Coast | High Seas Design House</title>
        <meta name="description" content="High Seas Design House offers effective digital solutions, including custom websites, digital ad campaigns, and videography work." />
      </Helmet>

      <div className={[classes.simpleHeader, classes.about].join(' ')}>
        <div className={classes.med2Def}>
          <h1>High Seas Design House is a<br/> full-service agency, driven by creativity, and committed to our clients.</h1>
           <div className={classes.linkRow}>
             <span>Our Services: </span><br className={classes.brSep} />
              <Link to="/websites" className={classes.textLink}>Web Design</Link>
              <Link to="/ecommerce" className={classes.textLink}>eCommerce</Link>
              <div className={classes.clearfix} />
              <Link to="/digital-advertising" className={classes.textLink}>Digital Advertising</Link>
              {/* <Link to="/videography" className={classes.textLink}>Videography</Link> */}
            </div>
        </div>
      </div>

      <div className={classes.wrap20}>
      <div className={[classes.fullRow, classes.sec2Shape, classes.comp2].join(' ')}>
        <div className={classes.csDiv}>
          <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
            <path d="M1200 0L0 0 598.97 114.72 1200 0z" class="shape-fill"></path>
          </svg>
        </div>
        <div className={classes.csDivFlip}>
          <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
            <path d="M1200 0L0 0 598.97 114.72 1200 0z" class="shape-fill"></path>
          </svg>
        </div>

        <div className={classes.med2Def}>
          <div className={[classes.col, classes.right, classes.pull].join(' ')}>
            <p className={classes.cat}>Located at The Wharf<br/> in Orange Beach, AL</p>
            <p>High Seas was founded in 2012, as a resource to give companies along the Gulf Coast a creative edge.</p>
            <p>We offer effective digital solutions to individuals, companies, and organizations looking to modernize the way they do business, and surpass their competitors.</p>
          </div>
        </div>
      </div>
      </div>


      <div className={[classes.fullRow, classes.comp3].join(' ')}>
        <div className={classes.medDef}>
          <div className={classes.bgRow}>
            <div className={[classes.col, classes.grid55, classes.left, classes.tspace].join(' ')}>
              <p>
                Our team is made up of talented individuals who each bring something valuable to the table. When you join forces with us, you put yourself ahead of the 
                pack, and you can feel comfortable knowing that you are in business with a group who loves the work that they do, and truly cares about the needs of every client.
              </p>

              <p className={classes.quoteB}>From the very beginning, we've thrived on creativity and innovation, and we've always had a passion for what we do.</p>
            </div>
          </div>
        </div>
        
        <div className={classes.clearfix} />
        <div className={classes.inside}>
          <div className={classes.review}>
            <p className={classes.tes}>“Working with the staff at High Seas has been such an incredible experience. They are helpful, extremely knowledgeable, and efficient. We recently did a major overhaul on our website and I can't say enough about how much they assisted us. They are genuine and really care about what they are doing and the accounts they serve. 
            Best move we made!”</p>
            <p className={classes.name}>- Sarah L., Barrow Fine Furniture</p>
          </div>
        </div>
      </div>
    </div>
  );
}
export default company;