import React from "react";
import { Helmet } from "react-helmet";
// import { Link } from "react-router-dom";
import classes from "./Shopify.module.scss"
import ShopContent from "./EcomContent";

const h1 = (<h1>eCommerce Services</h1>);

const ecommerce = () => {
  return (
    <div className={[classes.EcomPage, classes.vEcom].join(' ')}>
      <Helmet>
        <title>eCommerce Websites in Orange Beach, Alabama | High Seas Design House</title>
        <meta name="description" content="High Seas provides custom eCommerce sites, tailored to fit your brand and your needs. We are Shopify experts." />
      </Helmet>
      
      <ShopContent where="ecommerce" header={h1} />
    </div>
  );
}
export default ecommerce;