import React, {Component} from 'react';
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import { TinyButton as ScrollUpButton } from "react-scroll-up-button";
import classes from './App.module.scss';
import Email from "./components/Shared/Email";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import Home from './hoc/Home/Home';
import Fallback from './hoc/Fallback/Fallback';
import Company from './hoc/Company/Company';
import Websites from './hoc/Websites/Websites';
import SEM from './hoc/SEM/SEM';
import Shopify from './hoc/Shopify/Shopify';
import Ecommerce from './hoc/Shopify/Ecommerce';


export function withRouter( Child ) {
  return ( props ) => {
    const location = useLocation();
    const navigate = useNavigate();
    return <Child { ...props } navigate={ navigate } location={ location } />;
  }
}


class App extends Component {
  render() {
    let routes = (
	    <Routes>
        <Route exact={true} path="/" element={<Home />} />
        <Route path="/company" element={<Company />} />
        <Route path="/design-development" element={<Websites />} />
        <Route path="/websites" element={<Websites />} />
        <Route path="/sem" element={<SEM />} />
        <Route path="/digital-advertising" element={<SEM />} />
        <Route path="/ecommerce" element={<Ecommerce />} />
        <Route path="/shopify" element={<Shopify />} />
        <Route element={<Fallback />} />
        
        <Route path="/design" element={<Websites />} />
        <Route path="/squad_goals" element={<Company />} />
        <Route path="/sem" element={<SEM />} />
        <Route path="/about" element={<Company />} />
        <Route path="/clients" element={<Home />} />
        <Route path="/contact" element={<Home />} />
      </Routes>
	  );

    const pathName = window.location.pathname.replace('-','');
    const wrapClass = pathName.replace('/', '');

    const FootStyle = () => {
      if (window.location.pathname === '/ecommerce') {
        return (<Footer FooterType="f9Style" />);
      }
      return (<Footer FooterType="DefaultStyle" />);
    }
    const SneakyFooter = withRouter(FootStyle);

    return (
      <div className={[classes.hsWrap, classes[wrapClass]].join(' ')}>
        <Header />

        <main className={classes.Content}>
          {routes}
        </main>

        <div id="extensions" className={[classes.modalFull, classes.modalExt, "uk-flex-top"].join(' ')} data-uk-modal>
          <div className={[classes.modalDialog, "uk-modal-dialog uk-modal-body uk-margin-auto-vertical"].join(' ')}>
            <button className={[classes.closeIt, "uk-modal-close-default"].join(' ')} type="button" data-uk-close></button>
            <div className={classes.modalWrap}>
              <p className={classes.title}>Team Emails</p>
              <hr/>
              <div className={[classes.modalContent, classes.fullRow].join(' ')}>
                <div className={[classes.col, classes.grid47, classes.left].join(' ')}><p><b>Sam Sumlin</b><br/><Email where="modal" name="sam" /></p></div>
                <div className={[classes.col, classes.grid47, classes.right].join(' ')}><p><b>Will Burnette</b><br/><Email where="modal" name="will" /></p></div>
                <div className={[classes.col, classes.grid47, classes.left].join(' ')}><p><b>Clayton Blackwell</b><br/><Email where="modal" name="clayton" /></p></div>
                <div className={[classes.col, classes.grid47, classes.right].join(' ')}><p><b>Daniel Davis</b><br/><Email where="modal" name="daniel" /></p></div>
                <div className={[classes.col, classes.grid47, classes.left].join(' ')}><p><b>Kelli Parris</b><br/><Email where="modal" name="kelli" /></p></div>
                <div className={[classes.col, classes.grid47, classes.right].join(' ')}><p><b>Josh Yates</b><br/><Email where="modal" name="josh" /></p></div>
              </div>
            </div>
          </div>
        </div>
        
        <SneakyFooter />
        <ScrollUpButton
          ContainerClassName={classes.ToTopBtn}
          TransitionClassName="MyOverRideTransitionedClass"
          style={{ fill: 'rgb(255,255,255)', backgroundColor: '#000', padding: '0px 5px', bottom: '15px' }}
          EasingType='linear'
        />
      </div>
    );
  }
}
export default App;
