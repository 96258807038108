import React from 'react';
import Drawer from '@material-ui/core/Drawer';
// import Button from '@material-ui/core/Button';
import ClearIcon from '@material-ui/icons/Clear';
import classes from "./Header.module.scss";
import styles from "../Footer/Footer.module.scss";
import Phone from "../Shared/Phone";
import Email from "../Shared/Email";
import Social from "../Social/Social";
import NavItems from "./NavItems.js";

function TemporaryDrawer() {
  const [state, setState] = React.useState({
    left: false,
  });

  const toggleDrawer = (side, open) => event => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [side]: open });
  };

  const sideList = side => (
    <div
      className={[classes.list, classes.SideNavList].join(' ')}
      role="presentation"
      onClick={toggleDrawer(side, false)}
      onKeyDown={toggleDrawer(side, false)}
    >
      <div className={classes.closeIcon}><ClearIcon /></div>
      <NavItems navClass="SideNav" />
    </div>
  );

  return (
    <div className={[classes.NavDrawer, styles.ftDrawer].join(' ')}>
      <button className={classes.drawerButton} onClick={toggleDrawer('left', true)}>
        <span className={classes.menuIcon} data-uk-icon="icon:menu;"></span>
      </button>
     
      <Drawer anchor="left" open={state.left} onClose={toggleDrawer('left', false)} className={classes.cNavDrawer}>
        {sideList('left')}

        <div className={classes.drawerContact}>
          <hr/>
          <p className={classes.dcItem}><Email where="drawer" styleParent="mobileNav" /></p>
          <p className={classes.dcItem}><Phone where="drawer" styleParent="mobileNav" /></p>

          <div className={classes.dcItem}>
            <p className={classes.cat}>Address</p>
            <p>4751 Main Street<br/>
            Suite F-212<br/>
            Orange Beach, Al 36561</p>
          </div>

          <div className={classes.dcItem}>
            <p className={classes.cat}>Hours</p>
            <p>Monday-Friday: 8am-5pm</p>
          </div>

          <p><Social where="drawer" styleParent="mobileNav" /></p>

        </div>
      </Drawer>
    </div>
  );
}

export default TemporaryDrawer;