import React from "react";
import { Helmet } from "react-helmet";
// import { Link } from "react-router-dom";
// import Phone from "../../components/Shared/Phone";
// import image from "../../assets/images/homeAreas5.png";
import google from "../../assets/images/partners/google.png";
import bing from "../../assets/images/partners/bing.png";
const classes = require('./SEM.module.scss');

const sem = () => {
  return (
    <div className={classes.SEMPage}>
      <Helmet>
        <title>Digital Advertising with Measurable Results | High Seas Design House</title>
        <meta name="description" content="High Seas Design House offers highly effective digital marketing services that deliver impressive results." />
      </Helmet>


      <div className={[classes.simpleHeader, classes.sem, classes.blob].join(' ')}>
        <div className={classes.med2Def}>
          <h1>Digital advertising services <br/>with measurable results</h1>
          <p>Take the guesswork out of your marketing</p>
        </div>
      </div>

      <div className={[classes.fullRow, classes.sec2Shape, classes.sem2].join(' ')}>
        <div className={classes.csDiv}>
          <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
            <path d="M1200 0L0 0 598.97 114.72 1200 0z" class="shape-fill"></path>
          </svg>
        </div>
        <div className={classes.csDivFlip}>
          <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
            <path d="M1200 0L0 0 598.97 114.72 1200 0z" class="shape-fill"></path>
          </svg>
        </div>
      
        <div className={[classes.medDef, classes.s2Content].join(' ')}>
          <div className={[classes.col, classes.right].join(' ')}>
            <p>Attract new website visitors, or use the web to re-engage with your current audience. Whether you're selling a product or promoting a service, High Seas helps you reach customers where and when it matters.</p>
          </div>

          <div className={[classes.col, classes.left].join(' ')}>
            <div className={classes.logoRow}>
              <img src={google} className={classes.google} alt="Official Google Partners logo" />
              <img src={bing} className={classes.bing} alt="High Seas Design House is a Bing Partner" />
            </div>
          </div>
        </div>
      </div>

      <div className={[classes.fullRow, classes.sem3].join(' ')}>
        <div className={classes.medDef}>
          <p className={classes.secTitle}>Building Your Ad Campaign</p>

          <div className={[classes.col, classes.grid30, classes.left].join(' ')}>
            <p>We create customized digital ad campaigns that are tailored to your business goals.</p>
          </div>

          <div className={[classes.col, classes.grid30, classes.mid].join(' ')}>
            <p>Our team specializes in Paid Search (SEM) - but also reach your ideal customer across a wide spectrum of platform agnostic sources.</p>
          </div>
          
          <div className={[classes.col, classes.grid43, classes.right, classes.pull].join(' ')}>
            <div className={[classes.item, classes.item1].join(' ')}>  
              <p className={classes.cat}>Search Engine Marketing</p>
              <p>on Google Ads &amp; Bing Ads</p>
            </div>
            
            <div className={[classes.item, classes.item2].join(' ')}>
              <p className={classes.cat}>Digital Display</p>
              <p>Purchased Programmatically Across a Variety of Publishers</p>
            </div>
            
            <div className={[classes.item, classes.item3].join(' ')}>
              <p className={classes.cat}>Digital Video (Pre-Roll)</p>
              <p>on the Google Video Display Network</p>
            </div>
            
            <div className={[classes.item, classes.item1].join(' ')}><p className={classes.cat}>Google Shopping Campaigns</p></div>
          </div>

        </div>
      </div>
    </div>
  );
}
export default sem;