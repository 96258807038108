import React from "react";
import { Helmet } from "react-helmet";
// import { Link } from "react-router-dom";
import classes from "./Shopify.module.scss"
import ShopContent from "./EcomContent";
import shopifyText from "../../assets/images/shopifyText.png";

const h1 = (<h1>High Seas + <img src={shopifyText} alt="Shopify logo" className={classes.ilShop} /></h1>);

const shopify = () => {
  return (
    <div className={[classes.EcomPage, classes.vShop].join(' ')}>
      <Helmet>
        <title>Shopify Websites in Orange Beach, Alabama | High Seas Design House</title>
        <meta name="description" content="High Seas provides custom eCommerce sites, tailored to fit your brand and your needs. We are Shopify experts." />
      </Helmet>

      <ShopContent where="shopify" header={h1} />

      <div className={[classes.fullRow, classes.ecom3xx, classes.whyShop].join(' ')}>
        <div className={classes.med2Def}>
          <div className={classes.titleBox}><p className={classes.title}>Why Should You Choose <b>Shopify</b>?</p></div>
          <div className={[classes.col, classes.cblock, classes.grid45, classes.topc].join(' ')}><p>Shopify is our #1 choice for eCommerce clients. No matter what size operation you run, or what product you sell, Shopify will work for you.</p></div>
          <div className={classes.clearfix}/>
        
          <div className={[classes.col, classes.left, classes.grid40XX].join(' ')}>
            <div className={[classes.item, classes.l1].join(' ')}>
              <p>It gives you a convenient way to keep everything in one place -- from inventory management, to sales and reporting. So you can spend more time running your business, not running in circles.</p>
            </div>

            <div className={[classes.item, classes.l2].join(' ')}><p>Shopify makes the shipping process <br/>less painful, with calculated rates and discounted USPS shipping labels.</p></div>
          </div>

          <div className={[classes.col, classes.right, classes.grid36XX].join(' ')}>
            <div className={[classes.item, classes.r1].join(' ')}><p>You also get unlimited bandwidth, product inventory, and customer data. So you will never be charged based on website traffic.</p></div>
            <div className={[classes.item, classes.r2].join(' ')}><p>Because Shopify is level-1 PCI compliant, you don't have to worry about the security of your customers' credit card information.</p></div>
          </div>

          <div className={classes.clearfix}/>
          <div className={[classes.col, classes.cblock, classes.grid53, classes.bottomc].join(' ')}><p>The Shopify App is also an incredibly useful tool, as it allows you to manage inventory, orders, shipments, and more right from your phone.</p></div>
        </div>
      </div>


      <div style={{display:'none'}} className={[classes.fullRow, classes.ecom3, classes.whyShop].join(' ')}>
        <div className={classes.med2Def}>
          <div className={classes.titleBox}><p className={classes.title}>Why Should You Choose <b>Shopify</b>?</p></div>
          
          <div className={[classes.col, classes.cblock, classes.grid45, classes.topc].join(' ')}><p>Shopify is our #1 choice for eCommerce clients. No matter what size operation you run, or what product you sell, Shopify will work for you.</p></div>
          <div className={classes.clearfix}/>
        
          <div className={[classes.col, classes.left, classes.grid40].join(' ')}>
            <div className={[classes.item, classes.l2].join(' ')}>
              <p>It gives you a convenient way to keep everything in one place -- from inventory management, to sales and reporting. So you can spend more time 
                running your business, not running in circles.</p>
            </div>
            <div className={[classes.item, classes.l3].join(' ')}><p>You also get unlimited bandwidth, product inventory, and customer data. So you will never be charged based on website traffic.</p></div>
          </div>

          <div className={[classes.col, classes.right, classes.grid38XX].join(' ')}>
            <div className={[classes.item, classes.r1].join(' ')}><p>Shopify makes the shipping process less painful, with calculated rates and discounted USPS shipping labels.</p></div>
            <div className={[classes.item, classes.r2].join(' ')}>
              <p>The Shopify App is also an incredibly useful tool, as it allows you to manage inventory, orders, shipments, and more right from your phone.</p>
              {/* <p>Because Shopify is level-1 PCI compliant, you don't have to worry about the security of your customers' credit card information.</p> */}
            </div>
            {/* <div className={[classes.item, classes.r3].join(' ')}><p>Because Shopify is level-1 PCI compliant, you don't have to worry about the security of your customers' credit card information.</p></div> */}
          </div>

          <div className={classes.clearfix}/>
          <div className={[classes.col, classes.cblock, classes.grid45, classes.bottomc].join(' ')}>
              <p>Because Shopify is level-1 PCI compliant, you don't have to worry about the security of your customers' credit card information.</p>
            {/* <p>The Shopify App is also an incredibly useful tool, as it allows you to manage inventory, orders, shipments, and more right from your phone.</p> */}
          </div>
        </div>
      </div>
      
    </div>
  );
}
export default shopify;